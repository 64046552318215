import React from 'react';
import './App.css';
import ChatBox from './components/ChatBox';
import BetaBanner from './components/BetaBanner';


function App() {
    return (
        <div className="App">
            <BetaBanner />
            <ChatBox />
        </div>
    );
}

export default App;
