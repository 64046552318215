// FeedbackModal.js
import React, { useState } from 'react';
import './FeedbackModal.css';

function FeedbackModal({ isOpen, onClose, onSubmit }) {
  const [reason, setReason] = useState("");

  const handleSubmit = () => {
    onSubmit(reason);
    setReason("");
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="feedback-modal">
        <p>What was wrong with the message?</p>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Type your issue/suggestion here..."
        />
        <div className="modal-actions">
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}
export default FeedbackModal;



