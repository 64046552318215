import React, { useState, useRef, useEffect } from "react";
import FeedbackModal from "./FeedbackModal";
import { v4 as uuidv4 } from "uuid";
import logoImage from "../images/BIG---AskB.png";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";

function ChatBox() {
  // Chat
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  // Logging details
  const [userId, setUserId] = useState(null);
  const [sessionId] = useState(uuidv4());
  const queryParams = new URLSearchParams(window.location.search);
  const agentIdFromURL = queryParams.get("AgentId");
  const stateFromURL = queryParams.get("State");
  const productFromURL = queryParams.get("Product");
  const agentNameFromURL = decodeURIComponent(
    queryParams.get("AgentName") || "User"
  );
  const originFromURL = queryParams.get("Origin");
  const consumerEmailFromURL = queryParams.get("ConsumerEmail");
  const consumerNameFromURL = decodeURIComponent(
    queryParams.get("ConsumerName")
  );
  const [threadId, setThreadId] = useState(null);

  // Feedback
  const [feedback, setFeedback] = useState({});
  const [showFeedbackPrompt, setShowFeedbackPrompt] = useState(false);

  const handleSend = async () => {
    if (userInput.trim() === "") return;
    setUserInput("");

    const userMessage = {
      id: Date.now(),
      sender: "user",
      text: userInput,
      date: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    };

    const aiMessageId = Date.now() + 1;
    const loadingMessage = {
      id: aiMessageId,
      sender: "AskB",
      text: "",
      type: "loading",
      isFullyDisplayed: false,
      date: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    };

    setMessages((prev) => [...prev, userMessage, loadingMessage]);
    setIsLoading(true);

    //const apiUrl = `http://localhost:7071/api/CallOpenAI`;
    const apiUrl = `https://bfcgpt-openai-func.azurewebsites.net/api/CallOpenAI?code=5x42ytsspInIJbtPYu7w6hEplDN2E8K8NW57trtWb_CpAzFu6sdUeg==`;

    let payload = {};
    if (originFromURL === "ConsumerPortal") {
      payload = {
        query: userInput,
        history: messages,
        origin: originFromURL,
        consumer_email: consumerEmailFromURL,
        consumer_name: consumerNameFromURL,
        session_id: sessionId,
        thread_id: threadId, // Add thread_id to the payload
      };
    } else {
      payload = {
        query: userInput,
        history: messages,
        origin: originFromURL,
        user_id: userId,
        agent_name: agentNameFromURL,
        session_id: sessionId,
        state: stateFromURL,
        product: productFromURL,
        thread_id: threadId, // Add thread_id to the payload
      };
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json(); // Parse the response as JSON
      const aiResponse = data.response; // Extract the response text
      const responseThreadId = data.thread_id; // Extract the thread_id

      setThreadId(responseThreadId); // Set the thread_id

      const aiMessage = {
        id: aiMessageId,
        sender: "AskB",
        text: aiResponse,
        date: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        type: "regular",
        isFullyDisplayed: true,
        responseTo: userMessage.id, // Link to user's query
      };

      setMessages((prevMessages) => {
        return prevMessages.map((msg) =>
          msg.id === aiMessageId ? aiMessage : msg
        );
      });

      let currentText = "";
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < aiResponse.length) {
          currentText += aiResponse[i];
          setMessages((prevMessages) => {
            return prevMessages.map((msg) =>
              msg.id === aiMessageId
                ? {
                    ...msg,
                    text: currentText,
                    type: "regular",
                    isFullyDisplayed: false,
                  }
                : msg
            );
          });
          i++;
        } else {
          clearInterval(typingInterval);
          setMessages((prevMessages) => {
            return prevMessages.map((msg) =>
              msg.id === aiMessageId
                ? { ...msg, type: "regular", isFullyDisplayed: true }
                : msg
            );
          });
        }
      }, 35);
    } catch (error) {
      console.error("Error fetching AI response:", error);
      setMessages((prevMessages) => [
        ...prevMessages.filter((msg) => msg.id !== loadingMessage.id),
        {
          id: Date.now() + 2,
          sender: "AskB",
          text: "Sorry, I encountered an error.",
          date: new Date().toLocaleTimeString(),
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
      event.preventDefault();
    }
  };

  const handleClear = () => {
    setMessages([]);
    setThreadId(null);
  };

  const handleFeedback = (messageId, value) => {
    if (feedback[messageId] !== undefined) return;

    const aiMessage = messages.find((msg) => msg.id === messageId);

    const userMessage = messages.find(
      (msg) => msg.id === aiMessage?.responseTo
    );

    setFeedback({
      ...feedback,
      [messageId]: {
        value: value,
        userQuery: userMessage?.text,
        aiResponse: aiMessage?.text,
      },
    });

    if (value === "down") {
      setShowFeedbackPrompt(true);
    }
    if (value === "up") {
      // Optional: Handle positive feedback
    }
  };

  const handleCloseModal = () => {
    setShowFeedbackPrompt(false); // Close modal
  };

  const sendFeedback = async (reason) => {
    const feedbackApiUrl =
      'https://bfcgpt-openai-func.azurewebsites.net/api/FeedbackHandler?code=4Z25c2ftMdvR0vxvU6iv5f9qc6_B6fNuW6cDSCBhDxzTAzFu_rGU9A==';
    //const feedbackApiUrl = `http://localhost:7071/api/FeedbackHandler`; // Local testing

    const latestFeedbackEntry = Object.entries(feedback).find(
      ([key, value]) => value.value === "down"
    );

    if (!latestFeedbackEntry) return false;

    const [messageId, feedbackDetails] = latestFeedbackEntry;

    let feedbackPayload = {
      feedback: reason,
      session_id: sessionId,
      user_query: feedbackDetails.userQuery,
      ai_response: feedbackDetails.aiResponse,
      origin: originFromURL, // Add origin to distinguish between consumer and agent
    };

    // Add specific details based on the origin
    if (originFromURL === "ConsumerPortal") {
      feedbackPayload = {
        ...feedbackPayload,
        consumer_email: consumerEmailFromURL,
        consumer_name: consumerNameFromURL,
      };
    } else {
      feedbackPayload = {
        ...feedbackPayload,
        user_id: userId,
        agent_name: agentNameFromURL,
        state: stateFromURL,
        product: productFromURL,
      };
    }

    try {
      const response = await fetch(feedbackApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackPayload),
      });

      if (response.ok) {
        console.log("Feedback submitted successfully");
        return true;
      } else {
        console.error("Failed to submit feedback");
        return false;
      }
    } catch (error) {
      console.error("Error in submitting feedback:", error);
      return false;
    }
  };

  const handleSubmitFeedback = async (reason) => {
    setShowFeedbackPrompt(false);
    const isFeedbackSent = await sendFeedback(reason);
  };

  useEffect(() => {
    if (!userId) {
      if (agentIdFromURL) {
        setUserId(agentIdFromURL);
      } else {
        let fetchedUserId = getCookie("user_id");
        if (!fetchedUserId) {
          fetchedUserId = uuidv4();
          setCookie("user_id", fetchedUserId, 365);
        }
        setUserId(fetchedUserId);
      }
    }
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, userId, agentIdFromURL]);

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  }

  function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}${expires}; path=/`;
  }

  return (
    <div className="ChatBox">
      <div className="ChatBox-header">
        <img src={logoImage} alt="askB Logo" className="chatbox-logo" />
      </div>
      <div className="ChatBox-content">
        {messages.map((msg) => (
          <div key={msg.id} className={`message ${msg.sender}`}>
            <strong>
              {msg.sender === "user" ? `${agentNameFromURL}: ` : "AskB: "}
            </strong>
            <div className={`bubble ${msg.sender}`}>
              {msg.sender === "AskB" && msg.type === "loading" ? (
                <div className="loading-dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              ) : (
                <p>{msg.text}</p>
              )}
            </div>
            <div className="message-footer">
              <small className="message-time">{msg.date}</small>
              {msg.sender === "AskB" && msg.isFullyDisplayed && (
                <div className="feedback-icons">
                  <button
                    onClick={() => handleFeedback(msg.id, "up")}
                    className="feedback-button"
                    disabled={feedback[msg.id] !== undefined}
                  >
                    {feedback[msg.id] === "up" ? (
                      <ThumbUpIcon style={{ fontSize: 15 }} />
                    ) : (
                      <ThumbUpOutlinedIcon style={{ fontSize: 15 }} />
                    )}
                  </button>
                  <button
                    onClick={() => handleFeedback(msg.id, "down")}
                    className="feedback-button"
                    disabled={feedback[msg.id] !== undefined}
                  >
                    {feedback[msg.id] === "down" ? (
                      <ThumbDownIcon style={{ fontSize: 15 }} />
                    ) : (
                      <ThumbDownOutlinedIcon style={{ fontSize: 15 }} />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="ChatBox-footer">
        <textarea
          className="ChatBox-input"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message here..."
          rows="3"
        />
        <div className="ChatBox-actions">
          <button
            className="ChatBox-button"
            onClick={handleSend}
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
          <button className="ChatBox-button clear-button" onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>

      <div className="ChatBox-disclaimer">
        <p>
          Please be advised that this response is generated by an AI-powered
          assistant. For further assistance and clarification, please consult
          your underwriter.
        </p>
      </div>

      {/* FeedbackModal*/}
      <FeedbackModal
        isOpen={showFeedbackPrompt}
        onClose={handleCloseModal}
        onSubmit={handleSubmitFeedback}
      />
    </div>
  );
}
export default ChatBox;
