import React from 'react';
import './BetaBanner.css';

const BetaBanner = () => {
    return (
        <div className="beta-banner">
            Beta
        </div>
    );
}

export default BetaBanner;
